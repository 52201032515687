import React, { useMemo } from 'react'
import { prismicPropTypes } from 'helpers/proptypes'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { fetchPrismicLandingPage } from 'services/prismic'
import { initApplication } from 'helpers/SSR'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'
import {
  Head,
  PrismicHeroSection,
  HighlightsSection,
  PopularDestinationsSection,
  HostsSection,
  PlaylistEventsSection,
  ReviewsSection,
  ListSection,
  CTASection,
  HowItWorksSection,
  InstaBlogSection,
  PressSection,
  VideoSection,
  CobrandedFooter,
} from 'components/shared/prismic'
import { cacheRules } from 'helpers/cache'
import { getPrismicImage } from 'helpers/ImageManager'
import { COOKIE_KEYS } from 'helpers/cookies'
import { Section } from '@vizeat/components/es6/components/Section'

// TODO: this should be a getStaticProps but it needs a getStaticPaths because of the catch all route
// Difficulty in querying all the single occurences of an URL given that each element by language is
// returned by the API
export const getServerSideProps = async ({ locale, previewData, params: { slug }, query, res, req }) => {
  const cookieCurrency = req.cookies[COOKIE_KEYS.EW_CURRENCY]
  initApplication({ currency: query.c || cookieCurrency, locale })

  const data = await fetchPrismicLandingPage({ locale, previewData, slug: slug.join('/') })
  if (!data?.length) return { notFound: true }

  res.setHeader('Cache-Control', cacheRules.landingPage)

  return {
    props: {
      data,
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

export default function LandingPage({ data }) {
  const cobrandedFooter = useMemo(
    () => data.find(({ slice_type: sliceType }) => sliceType === 'cobranded_footer'),
    [data],
  )
  return (
    <DefaultLayout>
      <Header isTransparent />
      {data.map((section, i) => {
        switch (section.slice_type) {
          case 'seo_metadata':
            return <Head key={section.slice_type} staticFields={section.primary} />
          case 'hero_section':
            return <PrismicHeroSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'highlight_section':
            return <HighlightsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'popular_destinations_section':
            return (
              <PopularDestinationsSection
                key={`${section.slice_type}-${i}`}
                staticFields={section.primary}
                items={section.items}
                innerSectionProps={{ as: Section, maxWidth: { default: '100%', large: 'unset' } }}
              />
            )
          case 'hosts_section':
            return (
              <HostsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} items={section.items} />
            )
          case 'top_experiences_section':
            return <PlaylistEventsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'reviews_section':
            return (
              <ReviewsSection key={`${section.slice_type}-${i}`} items={section.items} staticFields={section.primary} />
            )
          case 'list_section':
            return (
              <ListSection key={`${section.slice_type}-${i}`} staticFields={section.primary} items={section.items} />
            )
          case 'cta_section':
            return <CTASection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'how_it_works_section':
            return (
              <HowItWorksSection
                key={`${section.slice_type}-${i}`}
                items={section.items}
                staticFields={section.primary}
              />
            )
          case 'social_media':
            return (
              <InstaBlogSection
                key={i}
                // Must pass props as undefined otherwise they are null and defaultProps don't kick in
                id={section.primary.anchor_id || undefined}
                backgroundColor={section.primary.section_background_color || undefined}
                instagramSectionTitle={section.primary.instagram_section_title || undefined}
                instagramSectionSubtitle={section.primary.instagram_section_subtitle || undefined}
                instagramSectionLink={section.primary.instagram_section_link || undefined}
                instagramSectionImages={[
                  section.primary.instagram_section_image_1,
                  section.primary.instagram_section_image_2,
                  section.primary.instagram_section_image_3,
                  section.primary.instagram_section_image_4,
                  section.primary.instagram_section_image_5,
                  section.primary.instagram_section_image_6,
                  section.primary.instagram_section_image_7,
                  section.primary.instagram_section_image_8,
                  section.primary.instagram_section_image_9,
                ]
                  .filter((x) => x)
                  .map((x) => getPrismicImage(x, '200x200'))}
                blogSectionTitle={section.primary.blog_section_title || undefined}
                blogSectionSubtitle={section.primary.blog_section_subtitle || undefined}
                blogSectionLink={section.primary.blog_section_link || undefined}
                blogSectionImage={
                  section.primary.blog_section_image
                    ? getPrismicImage(section.primary.blog_section_image, '500x500')
                    : undefined
                }
                blogSectionBoxTitle={section.primary.blog_section_box_title || undefined}
                blogSectionBoxSubtitle={section.primary.blog_section_box_subtitle || undefined}
                blogSectionBoxCallToAction={section.primary.blog_section_box_call_to_action || undefined}
              />
            )
          case 'press_section':
            return <PressSection key={`${section.slice_type}-${i}`} items={section.items} />
          case 'video_section':
            return <VideoSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          default:
            return null
        }
      })}
      <PressSection />
      {cobrandedFooter ? <CobrandedFooter staticFields={cobrandedFooter?.primary} /> : <Footer />}
    </DefaultLayout>
  )
}

LandingPage.propTypes = prismicPropTypes
